body { 
    padding-top: 120px; 
}


.filter-alert {
    display:inline-block;
    margin-right: 10px;
    padding: 0.5rem 1rem;
  }

  button.filter-close {
    padding-left: 0.5rem;
}

div.job-card-inner {
  min-height: 7rem;
}

.job-filter-row {
  margin-top: 15px;
}

@media (min-width: 576px) {
  

  .card-columns {
      column-count: 2;
  }
}

@media (min-width: 768px) {
  .job-filter-row {
    margin-top: 0px;
  }

  .card-columns {
      column-count: 2;
  }
}

@media (min-width: 992px) {
  .card-columns {
      column-count: 3;
  }
}

@media (min-width: 1200px) {
  .card-columns {
      column-count: 3;
  }
}

.card-body {
  padding-top: 0px;
}

div.job-jumbotron {
  padding-top: 0px;
  padding-bottom: 2rem;
}

/*ribbon*/
.ribbon-box {
 /*  width: 200px; height: 300px; */
  position: relative;
  border: 1px solid #BBB;
  background: #EEE;
}
.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #79A70A;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}

nav.navbar {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.4rem;
  }
}

div.job-card-div {
  padding-bottom: 15px;
}

.time-filter-btn-link {
  padding: 0px;
}

h4.card-title {
  font-size: 1.0rem;
}

p.job-card-text {
  min-height: 42px;
}